import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { Theme, Row, Col } from '@commonsku/styles';
import { oauth, makeCancelable, parseMysqlDate, formatMoney } from '../../utils';
import { LabeledSelect } from '../helpers';
import { getClientDropdown } from '../../selectors/dropdowns';
import { createLoadClientList } from '../../actions/client';
import { createLoadStatuses, createLoadUserReps } from '../../actions';
import DebouncedSearchBox from '../DebouncedSearchBox';

export const getStatusOptions = createSelector(
  (state) => state.dropdowns.statuses || [],
  values => values.map(v => ({ value: v.status_id, label: v.status_name }))
);
export const getUserRepOptions = createSelector(
  (state) => state.dropdowns.users || [],
  values => [{
    value: "inactive_reps",
    label: "Inactive Reps"
  }].concat(values
    .filter(v => v.contact_full_name.trim())
    .map(v => ({
      value: v.user_id,
      label: v.contact_full_name
    }))
  )
);
const getClientOptions = createSelector(getClientDropdown,
  values => values.filter(v => v.client_name).map(v => ({ value: v.client_id, label: v.client_name }))
);
const ShopTypes = [
  {label: 'Company', value: 'Company'},
  {label: 'Marketing', value: 'Marketing'},
  {label: 'Pop-up', value: 'Pop-up'},
];

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      search_terms: {
        search: '',
        client_id: '',
        type: '',
        client_rep_id: '',
        status_id: '',
      },
      page: 0,
      loading: false,
      shops: [],
      sort: { last_order: 'DESC' },
    };

    this.handleSortShops = this.handleSortShops.bind(this);
  }

  componentDidMount() {
    const { onLoadClients, onLoadUserReps, onLoadStatuses, identity } = this.props;
    this.onSearch(this.state.search_terms);
    onLoadClients();
    onLoadUserReps(identity.company_id);
    onLoadStatuses();
  }

  componentDidUpdate(prevProps, prevState) {
    const { search_terms } = prevState;
    !_.isEqual(search_terms, this.state.search_terms)
      && this.onSearch(this.state.search_terms);
  }

  onSearch(search_terms) {
    const search = makeCancelable(oauth('GET', 'shop', search_terms));
    search.promise.then(
      ({ json }) => {
        this.onSearchResult(json.shops);
      }
    );
  }

  onSearchResult(shops) {
    this.setState({
      loading: false,
      shops
    }, function() {
      this.handleSortShops(Object.keys(this.state.sort)[0], false);
    });
  }

  handleSortShops(column, is_click=true) {
    if(typeof column !== 'string') {
      return;
    }

    const { shops } = this.state;
    let sort_order;
    if(is_click) {
      sort_order = this.state.sort && this.state.sort.hasOwnProperty(column) && this.state.sort[column] === 'DESC' ? 'ASC' : 'DESC';
    }else{
      sort_order = this.state.sort[column];
    }

    this.setState({ sort: { [column]: sort_order } }, function() {
      let sort_shops;
      const sorting_shops = (sort_shops, column, sort_order) => {
        if(['contact_name'].indexOf(column) !== -1) {
          if (sort_order === 'ASC') {
            sort_shops.sort((a, b) => {
              let x, y;
              if(column === 'contact_name') {
                x = a.contact_first_name.toLowerCase() + a.contact_last_name.toLowerCase();
                y = b.contact_first_name.toLowerCase() + b.contact_last_name.toLowerCase();
              }
              if(x < y) { return -1; }
              if(x > y) { return 1; }
              return 0;
            });
          } else {
            sort_shops.sort((a, b) => {
              let x, y;
              if(column === 'contact_name') {
                x = a.contact_first_name.toLowerCase() + a.contact_last_name.toLowerCase();
                y = b.contact_first_name.toLowerCase() + b.contact_last_name.toLowerCase();
              }
              if(x > y) { return -1; }
              if(x < y) { return 1; }
              return 0;
            });
          }
        } else if(column === 'date_created' || column === 'last_order') {
          if (sort_order === 'ASC') {
            sort_shops.sort((a, b) => (Date.parse(a[column]) || 0) - (Date.parse(b[column]) || 0));
          }else{
            sort_shops.sort((a, b) => (Date.parse(b[column]) || 0) - (Date.parse(a[column]) || 0));
          }
        } else if(['order_total', 'shop_number', 'order_count'].indexOf(column) !== -1) {
          const checkoutsSort = (a, b) => column !== 'order_count'
            ? 0 : parseFloat((sort_order === 'ASC' ? a : b)['checkout_count'] || 0) -
                  parseFloat((sort_order === 'ASC' ? b : a)['checkout_count'] || 0);
          //number sorting
          if (sort_order === 'ASC') {
            sort_shops.sort((a, b) => parseFloat(a[column] || 0) - parseFloat(b[column] || 0) || checkoutsSort(a, b));
          } else {
            sort_shops.sort((a, b) => parseFloat(b[column] || 0) - parseFloat(a[column] || 0) || checkoutsSort(a, b));
          }
        } else {
          //string sorting
          if (sort_order === 'ASC') {
            sort_shops.sort((a, b) => {
              let x = (a[column] || '').toLowerCase();
              let y = (b[column] || '').toLowerCase();
              if (x < y) { return -1; }
              if (x > y) { return 1; }
              return 0;
            });
          } else {
            sort_shops.sort((a, b) => {
              let x = (a[column] || '').toLowerCase();
              let y = (b[column] || '').toLowerCase();
              if (x > y) { return -1; }
              if (x < y) { return 1; }
              return 0;
            });
          }
        }

        return sort_shops;
      };
      switch (column) {
        default:
          sort_shops = sorting_shops(shops, column, sort_order);
          break;
      }
      this.setState({ shops: sort_shops, loading: false });
    });
  }

  onChangeFilter(field) {
    return (v) => (
      this.setState({search_terms: {...this.state.search_terms, [field]: v ? v.value : ''}})
    );
  }

  renderFilters() {
    const { client_id, client_rep_id, status_id, type } = this.state.search_terms;
    const { clients, user_reps, statuses } = this.props;
    return(
      <Theme>
        <Row>
          <Col xs md={2.5} padded>
            <DebouncedSearchBox
              label="Name"
              placeholder="Type Shop or Client name..."
              className="csku-input"
              maxLength={200}
              handleSearch={(search) => {
                this.setState({search_terms: {...this.state.search_terms, search: search}});
              }}
              debounceInterval={650}
            />
          </Col>
          <Col xs md={2.5} padded>
            <LabeledSelect isClearable
              label="Client"
              options={clients || []}
              value={client_id}
              onChange={this.onChangeFilter('client_id')}
              className="csku-select"
              name="client_id"
            />
          </Col>
          <Col xs md={2.5} padded>
            <LabeledSelect isClearable
              label="Client Rep"
              options={user_reps}
              value={client_rep_id}
              onChange={this.onChangeFilter('client_rep_id')}
              className="csku-select"
              name="client_rep_id"
            />
          </Col>
          <Col xs md={2.5} padded>
            <LabeledSelect isClearable
              label="Type"
              options={ShopTypes}
              value={type}
              onChange={this.onChangeFilter('type')}
              className="csku-select"
              name="type"
            />
          </Col>
          <Col xs md={2} padded>
            <LabeledSelect isClearable
              label="Status"
              options={statuses}
              value={status_id}
              onChange={this.onChangeFilter('status_id')}
              className="csku-select"
              name="status_id"
            />
          </Col>
        </Row>
      </Theme>
    );
  }

  renderLoading() {
    return (
      <div className='row popup-content column'>
        <div className='small-12 columns'>
          <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
        </div>
      </div>
    );
  }

  renderSortColumnHeader(column, label) {
    const headerStyle= {
      top: '120px',
      background: '#EDF2F5'
    };

    return (
      <td style={column === 'order_total' ? { ...headerStyle, textAlign: 'right' } : headerStyle} className="table-cell-sticky" onClick={e => { e.preventDefault(); this.handleSortShops(column); }}>
        <span style={{ paddingRight: '5px' }}>{label}</span>
        <span>
            {this.state.sort && this.state.sort[column] && this.state.sort[column] === 'ASC' ?
                <span>&#x25B2;</span>
            : null}
            {this.state.sort && this.state.sort[column] && this.state.sort[column] === 'DESC' ?
                <span>&#x25BC;</span>
            : null}
        </span>
      </td>
    );
  }

  renderHeader() {
    return(
      <tr>
        {this.renderSortColumnHeader('shop_number', 'Name')}
        {this.renderSortColumnHeader('client_name', 'Client')}
        {this.renderSortColumnHeader('type', 'Type')}
        {this.renderSortColumnHeader('date_created', 'Created')}
        {this.renderSortColumnHeader('last_order', 'Last Order')}
        {this.renderSortColumnHeader('contact_name', 'Client Rep')}
        {this.renderSortColumnHeader('order_total', 'Total Value')}
        {this.renderSortColumnHeader('order_count', <># of Orders <br/>(# of Checkouts)</>)}
        {this.renderSortColumnHeader('status_name', 'Status')}
      </tr>
    );
  }

  renderRow(s) {
    return (
      <tr key={s.shop_id} onClick={e => {e.preventDefault(); window.open(`/shop/${s.shop_number}`, '_blank');}}>
        <td>#{s.shop_number} &mdash; {s.shop_name}</td>
        <td>{s.client_name}</td>
        <td>{s.type}</td>
        <td>{parseMysqlDate(s.date_created)}</td>
        <td>{s.last_order ? parseMysqlDate(s.last_order) : '--'}</td>
        <td>{s.contact_first_name} {s.contact_last_name}</td>
        <td style={{ textAlign: 'right' }}>{s.order_total ? '$' + formatMoney(s.order_total) : ''}</td>
        <td>{s.order_count} {s.type == 'Pop-up' ? `(${s.checkout_count})` : ''}</td>
        <td>{s.status_name}</td>
      </tr>
    );
  }

  renderNoRow() {
    return (
      <tr><td colSpan="8" style={{ textAlign: 'center' }}>No shops yet</td></tr>
    );
  }

  render() {
    return (
      <div ref={ref => this.root = ref} className="row small-12 columns full-width">
        <ul className="tabs" data-tabs style={{background: '#EDF2F5', border: 'none'}}>
        </ul>
        <div className="row collapse" style={{ maxWidth: 'initial', marginTop: '10px', fontSize: '14px' }}>
        </div>
        {this.state.loading ?
          this.renderLoading()
        : <>
          {this.renderFilters()}
          <table style={{ marginBottom: 0 }}>
            <thead style={{ fontSize: '14px' }}>
              {this.renderHeader()}
            </thead>
            <tbody style={{ fontSize: '14px' }}>
              {this.state.shops.length ?
                this.state.shops.map(s =>
                  this.renderRow(s)
                )
              : this.renderNoRow()}
            </tbody>
          </table>
        </>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  identity: state.identity,
  user_id: state.identity.user_id,
  clients: getClientOptions(state),
  user_reps: getUserRepOptions(state),
  statuses: getStatusOptions(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadClients: () => dispatch(createLoadClientList()),
  onLoadUserReps: (parent_id, parent_type='TENANT') =>
    dispatch(createLoadUserReps(parent_id, parent_type, true)),
  onLoadStatuses: () => dispatch(createLoadStatuses('SHOP')),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
