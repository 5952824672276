import React from 'react';
import { useParams, useLocation, Routes, Route, BrowserRouter } from "react-router-dom";

import ShopsApp from '../../containers/ShopsApp';
import NoMatch from '../NoMatch';
import ErrorBoundary from '../ErrorBoundary';

const AppRoute = React.memo(({ show }) => {
  const params = useParams();
  const location = useLocation();

  if (show) {
    return <ShopsApp params={params} location={location} />;
  }

  return <NoMatch location={location} />;
});

const ShopsRouter = ({ location }) => (
  <BrowserRouter basename={location}>
    <Routes>
      <Route
        path="/shops.php"
        element={<AppRoute show />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path={"*"}
        element={<AppRoute noMatch />}
        ErrorBoundary={ErrorBoundary}
      />
    </Routes>
  </BrowserRouter>
);

export default ShopsRouter;
