import React, { Component } from 'react';
import { connect } from 'react-redux';

import { oauth, getIdentityUtils } from '../../utils';

class ShopsIntro extends Component {
    constructor(props) {
        super(props);

        this.acceptShopCharges = this.acceptShopCharges.bind(this);
    }

    acceptShopCharges() {
        oauth('PUT', `tenant/${this.props.identity.company_id}`, { accept_shop_charges: 1 });
        window.location.reload();
    }

    render() {
        const { identity } = this.props;

        const { hasCapabilities, isTenant } = getIdentityUtils(identity);

        return (
            <div className="shop-charge">
                <div className="shops-popup-inner">
                    <div className="row">
                        <div className="row large-12 container">
                            <h1 style={{ fontSize: '2rem' }}>Fully-integrated and easy-to-build, commonsku shops are the easiest way to build a quick-ordering solution for your client.</h1>
                            <img src="images/shops-laptop2.png" />
                            <p className="p-description">No complicated inventory. No complicated purchasing methods. commonsku Shops are a frictionless experience that eliminates communication hassles, complicated ordering, and multiple data entries. Shops are free to set up for all commonsku users, and are subject to a 2.5% transaction fee on orders placed.</p>
                            <p className="p-description">In order to start using the Shops feature, an Admin level user needs to activate access.</p>
                            {isTenant() && hasCapabilities(['MODIFY-SETTINGS']) ?
                                <a className="button radius create-shop-btn" onClick={e => {e.preventDefault(); this.acceptShopCharges();}}>Activate Shops Feature</a>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    identity: state.identity
});

const mapDispatchToProps = (dispatch, ownProps) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ShopsIntro);