import React, { Component } from 'react';

import List from './List';
import ShopsIntro from './ShopsIntro';

import { oauth } from '../../utils';

class ShopsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accept_shop_charges: false,
      loading: true
    };
  }

  componentDidMount() {
    const _this = this;
    oauth('GET', 'tenant/is-accepted-shop-charges', {}).then(({ json }) => {
      _this.setState({ accept_shop_charges: json.accept_shop_charges == 1 ? true : false, loading: false });
    });
  }

  renderLoading() {
    return (
      <div className='row popup-content column'>
        <div className='small-12 columns'>
          <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
        </div>
      </div>
    );
  }

  render() {
    const { accept_shop_charges, loading } = this.state;

    return (
      <div className="main-content">
        {loading ? this.renderLoading() : !loading && accept_shop_charges ? <List /> : !loading && !accept_shop_charges ? <ShopsIntro /> : null}
      </div>
    );
  }
}

export default ShopsPage;