import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getIdentityUtils, oauth } from '../utils';

import { getPopups } from '../selectors';

import { createNewFeaturePopup } from '../actions/popup';
import { createAddTemp } from '../actions/temp';

import Header from './Header';
import MainSection from './MainSection';

import ErrorBoundary from '../components/ErrorBoundary';
import Overlay from '../components/Overlay';
import ShopsPage from '../components/shop/ShopsPage';
import CreateShop from '../components/shop/CreateShop';

class ShopsApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accept_shop_charges: false
    };
  }

  componentDidMount() {
    const { user_id, onCreateNewFeaturePopup } = this.props;
    const _this = this;
    oauth('GET', 'tenant/is-accepted-shop-charges', {}).then(({ json }) => {
      _this.setState({ accept_shop_charges: json.accept_shop_charges == 1 ? true : false });
    });

    oauth('GET', `user/${user_id}`, { should_show_popup: true }).then(({ json }) => {
      if(json.show_popup == 1) {
        onCreateNewFeaturePopup();
      }
    });
  }

  render() {
    const { identity, popups, onCreateShop, create_shop } = this.props;
    const { hasCapabilities } = getIdentityUtils(identity);

    return (
      <ErrorBoundary>
        <Header>
          <div className="columns">
            <h4 style={{ display: 'inline-block' }}>Shops</h4>
            {hasCapabilities('CREATE-SHOP') && this.state.accept_shop_charges && <a className="button" style={{ float: 'right' }} onClick={onCreateShop}>Create Shop</a>}
            <a className="button alt" style={{ float: 'right', marginRight: '10px' }} target="_blank" rel="noopener noreferrer" href="https://meetings.hubspot.com/shops/shops-consultation-call">Book Shop Talk</a>
          </div>
        </Header>
        <MainSection popups={popups}>
          <ShopsPage identity={identity} />
        </MainSection>
        <Overlay popups={popups} />
        {create_shop ? <CreateShop /> : null}
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  identity: state.identity,
  popups: getPopups(state),
  create_shop: state.temp.create_shop,
  user_id: state.identity.user_id
});

const mapDispatchToProps = dispatch => ({
  //onCreateShop: () => dispatch(createCreateShopPopup())
  onCreateShop: () => dispatch(createAddTemp('create_shop', true)),
  onCreateNewFeaturePopup: () => {
    dispatch(createNewFeaturePopup());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopsApp);
